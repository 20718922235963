.MuiAppBar-root {
  background-image: linear-gradient(90deg, #3d3993, #ed1863);
  color: #fff !important;
}

.MuiButton-containedPrimary {
  background-image: linear-gradient(90deg, #21a881, #21a881, #6bbe59, #6bbe59);
  color: #fff !important;
}

.MuiBottomNavigation-root {
  background-image: linear-gradient(90deg, #3d3993, #ed1863);
}
